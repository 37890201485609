import { useParams } from "react-router-dom"
import { useSkuByLotQuery } from "../graphql/hooks/queries.generated"
import Switcher from "../landingPages/Switcher"
import SimpleLoadingPlaceholder from "../components/SimpleLoadingPlaceholder"

export type SkyByLotProps = {
  gtin?: string
  lotCode?: string
}

export const SkuByLot = (props: SkyByLotProps) => {
  const { skuID, gtin: gtinFromParams, lotCode: lotCodeFromParams } = useParams()
  const { gtin: gtinFromProps, lotCode: lotCodeFromProps } = props
  const gtin = gtinFromProps ?? gtinFromParams
  const lotCode = lotCodeFromProps ?? lotCodeFromParams

  const skip = (!skuID && !gtin) || !lotCode
  const { data, loading, error } = useSkuByLotQuery({
    variables: { skuID, gtin, lotCode },
    skip,
  })

  if (error) {
    return (
      <div>
        <h1>Error Loading Sku</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  if (!data?.lot.sku) {
    if (loading) return <SimpleLoadingPlaceholder />
    return <h1>404: Sku Not Found!</h1>
  }

  return <Switcher sku={data.lot.sku} batchCodePrefill={lotCode} />
}

export default SkuByLot
