import { createContainer } from "unstated-next"

export type GS1Array = {
  /** See potential record keys here: https://ref.gs1.org/ai/ */
  GS1: Record<string, string | undefined>
  other: any
}

/** Source: https://github.com/gs1/GS1DigitalLinkToolkit.js */
export type GS1DigitalLinkToolkit = {
  decompressGS1DigitalLink(compressedDigitalLinkURI: string, useShortText: boolean, uriStem: string): string

  decompressGS1DigitalLinkToStructuredArray(compressedDigitalLinkURI: string): any

  /**
   * Conver a set of gs1 parameters into a gs1 2d barcode uri (a QR code).
   * @param elementStrings Example: "(3103)000189(01)05412345000013(3923)2172(10)ABC&+123"
   * @param useShortText Set true if you would like to use AI text tags instead of AI numbers in the uri. Eg: /gtin/ instead of /01/
   * @param uriStem The base of the uri, for example use "http://example.org" to get "http://example.org/gtin/05412345000013/lot/ABC?3103=000189&3923=2172"
   */
  gs1ElementStringsToGS1DigitalLink(elementStrings: string, useShortText: boolean, uriStem: string): string

  /**
   * Convert a gs1 2d barcode uri into a set of parameter in string format
   * @param gs1DigitalLinkURI Example: "http://example.org/gtin/054123450013/lot/ABC%26%2B123?3103=000189&3923=2172"
   * @param brackets Encase AIs in brackets. Eg: "(01)00054123450013(10)ABC&+123(3103)000189(3923)2172" instead of "0100054123450013310300018910ABC&+123�39232172"
   */
  gs1digitalLinkToGS1elementStrings(gs1DigitalLinkURI: string, brackets: boolean): string

  /**
   * The method extractFromGS1digitalLink(gs1DigitalLinkURI) converts a GS1 Digital Link URI to an associative array of GS1 application identifiers and their values.
   *
   * The input parameter gs1DigitalLinkURI is expected to be a string representation of a valid GS1 Digital Link URI.
   *
   * The method returns a JavaScript object containing numeric GS1 Application Identifier keys and their corresponding values, e.g. {"01":"05412345000013","10":"ABC&+123"}
   * @param gs1DigitalLinkURI
   */
  extractFromGS1digitalLink(gs1DigitalLinkURI: string): GS1Array
}

declare global {
  interface Window {
    new_GS1DigitalLinkToolkit: () => GS1DigitalLinkToolkit
  }
}

const toolkit = window.new_GS1DigitalLinkToolkit()

const Gs1Toolkit = createContainer(() => toolkit)
export default Gs1Toolkit
export const useGs1Toolkit = Gs1Toolkit.useContainer.bind(Gs1Toolkit)
