import { useStyletron } from "baseui"

interface ErrorDisplayProps {
  className?: string
  errorMessage: string
}

const ErrorDisplayComponent = (props: ErrorDisplayProps) => {
  const { className, errorMessage } = props
  //#region Styles
  const [css, theme] = useStyletron()
  const errorStyle = css({
    ...theme.typography.font1250,
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
  //#endregion
  return (
    <div className={className}>
      <div className={errorStyle}>{errorMessage}</div>
    </div>
  )
}

export default ErrorDisplayComponent
