export enum AnalyticsEventName {
  SCAN_QR1 = "scan_qr1",
}

export enum SkuFeatures {
  PRODUCT_PHOTOS = "PRODUCT_PHOTOS",
  BATCH_CERTIFICATION = "BATCH_CERTIFICATION",
  ATTRIBUTE_VISIBILITY = "ATTRIBUTE_VISIBILITY",
  CERTIFICATIONS = "CERTIFICATIONS",
  LOCALIZED_RECYCLING = "LOCALIZED_RECYCLING",
  CUSTOMER_TESTIMONIALS = "CUSTOMER_TESTIMONIALS",
  INTERACTIVE_MAP = "INTERACTIVE_MAP",
  IMAGE_GALLERY = "IMAGE_GALLERY",
  GS1_2D_BARCODE = "GS1_2D_BARCODE",
  FEATUREDIN_WIDGET = "FEATUREDIN_WIDGET",
  CUSTOM_LINKS = "CUSTOM_LINKS",
  SOCIAL_INTEGRATION = "SOCIAL_INTEGRATION",
  VIDEO_UPLOAD = "VIDEO_UPLOAD",
  YOU_MIGHT_ALSO_LIKE = "YOU_MIGHT_ALSO_LIKE",
  PRODUCT_GPT = "PRODUCT_GPT",
  TRACEABILITY = "TRACEABILITY",
  COMPETITIONS = "COMPETITIONS",
  LOYALTY_REWARD = "LOYALTY_REWARD",
  PROVENANCE_STORY = "PROVENANCE_STORY",
  BLOCKCHAIN_VERIFICATION = "BLOCKCHAIN_VERIFICATION",
}