// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Can't find a batch number?",
  GetBatchNumber: "Get batch number",
  InputBatchNumber: "Input batch number",
  ProductTracking: "Product Tracking",
  IngredientsTracking: "Ingredients Tracking",
  ProductDetails: "Product Details",
  SeeMyJourney: "See My Journey",
  ReviewsTitle: "What people said about us",
  QuotesTitle: "Featured In",
  RecommendedSkusTitle: "You might also like",
  ViewCertificates: "View Certificate",
  MoreInfoCertificate: "More Information",
  AttributeDocument: "Document",
  FooterText: "Powered by",
  LifeStyleImageTitle: "Explore",
  FollowUs: "Follow us",
  // New Landing page / Template 5
  DetailsTabName: "Details",
  PassportTabName: "Passport",
  CertificatesTabName: "Certificates",
  SocialsTabName: "Socials",
  HomeLeftNav: "Home",
  AboutLeftNav: "About us",
  TermsLeftNav: "Terms & conditions",
  PolicyLeftNav: "Privacy policy",
  ReviewsButton: "Reviews",
  NutritionalButtton: "Nutritional Information",
  VideoButton: "Videos",
  CertificatesHeading: "Certificates",
  SocialMediaHeading: "Social Media",
  VideosHeading: "Videos",
  PassportHeading: "Passport",
  AttributesHeading: "Attributes",
  ReviewsHeading: "Reviews",
  ProductTrackingHeading: "Product Tracking",
  MoreReviewsHeading: "More Reviews",
  AvailableAtHeading: "Available at",
  NutritionHeading: "Nutrition",
  CompetitionHeading: "Competition",
  LoyaltyHeading: "Loyalty",
  PromotionHeading: "Promotions",
  SuccessfulFormSubmitMessage:
    "Thankyou for your submission. Your receipt will be verified and confirmation of your entry will be emailed to you.",
  SubmitBtn: "Submit",
  TermsAndConditionBtn: "Terms and Condition",
  UploadReceiptBtn: "Upload Your Receipt",
  SupplyChainStory: "Supply Chain Story",
}
