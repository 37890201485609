import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AnalyticsFragmentFragmentDoc, FileFragmentFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloseMutationVariables = Types.Exact<{
  pid?: Types.InputMaybe<Types.Scalars['String']>;
  rid: Types.Scalars['String'];
}>;


export type CloseMutation = { __typename?: 'Mutation', closeProduct: { __typename: 'PublicProductRegistry' } };

export type EngagementSubmitMutationVariables = Types.Exact<{
  input: Types.PromotionEntryInput;
}>;


export type EngagementSubmitMutation = { __typename?: 'Mutation', promotionEntryCreate: { __typename?: 'PromotionEntry', id: string } };

export type AddToAnalyticsMutationVariables = Types.Exact<{
  input: Types.AnalyticsInput;
}>;


export type AddToAnalyticsMutation = { __typename?: 'Mutation', recordAnalytic: { __typename?: 'Analytics', eventName?: string | null, createdAt?: any | null, eventData?: any | null, itemIdentifier?: any | null } };

export type FileUploadMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
}>;


export type FileUploadMutation = { __typename?: 'Mutation', fileUpload: { __typename?: 'File', name: string, url: string, file_id?: string | null } };


export const CloseDocument = gql`
    mutation close($pid: String, $rid: String!) {
  closeProduct(productID: $pid, registerID: $rid) {
    __typename
  }
}
    `;
export type CloseMutationFn = Apollo.MutationFunction<CloseMutation, CloseMutationVariables>;

/**
 * __useCloseMutation__
 *
 * To run a mutation, you first call `useCloseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeMutation, { data, loading, error }] = useCloseMutation({
 *   variables: {
 *      pid: // value for 'pid'
 *      rid: // value for 'rid'
 *   },
 * });
 */
export function useCloseMutation(baseOptions?: Apollo.MutationHookOptions<CloseMutation, CloseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseMutation, CloseMutationVariables>(CloseDocument, options);
      }
export type CloseMutationHookResult = ReturnType<typeof useCloseMutation>;
export type CloseMutationResult = Apollo.MutationResult<CloseMutation>;
export type CloseMutationOptions = Apollo.BaseMutationOptions<CloseMutation, CloseMutationVariables>;
export const EngagementSubmitDocument = gql`
    mutation EngagementSubmit($input: PromotionEntryInput!) {
  promotionEntryCreate(input: $input) {
    id
  }
}
    `;
export type EngagementSubmitMutationFn = Apollo.MutationFunction<EngagementSubmitMutation, EngagementSubmitMutationVariables>;

/**
 * __useEngagementSubmitMutation__
 *
 * To run a mutation, you first call `useEngagementSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEngagementSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [engagementSubmitMutation, { data, loading, error }] = useEngagementSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEngagementSubmitMutation(baseOptions?: Apollo.MutationHookOptions<EngagementSubmitMutation, EngagementSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EngagementSubmitMutation, EngagementSubmitMutationVariables>(EngagementSubmitDocument, options);
      }
export type EngagementSubmitMutationHookResult = ReturnType<typeof useEngagementSubmitMutation>;
export type EngagementSubmitMutationResult = Apollo.MutationResult<EngagementSubmitMutation>;
export type EngagementSubmitMutationOptions = Apollo.BaseMutationOptions<EngagementSubmitMutation, EngagementSubmitMutationVariables>;
export const AddToAnalyticsDocument = gql`
    mutation addToAnalytics($input: AnalyticsInput!) {
  recordAnalytic(input: $input) {
    ...AnalyticsFragment
  }
}
    ${AnalyticsFragmentFragmentDoc}`;
export type AddToAnalyticsMutationFn = Apollo.MutationFunction<AddToAnalyticsMutation, AddToAnalyticsMutationVariables>;

/**
 * __useAddToAnalyticsMutation__
 *
 * To run a mutation, you first call `useAddToAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToAnalyticsMutation, { data, loading, error }] = useAddToAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToAnalyticsMutation(baseOptions?: Apollo.MutationHookOptions<AddToAnalyticsMutation, AddToAnalyticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToAnalyticsMutation, AddToAnalyticsMutationVariables>(AddToAnalyticsDocument, options);
      }
export type AddToAnalyticsMutationHookResult = ReturnType<typeof useAddToAnalyticsMutation>;
export type AddToAnalyticsMutationResult = Apollo.MutationResult<AddToAnalyticsMutation>;
export type AddToAnalyticsMutationOptions = Apollo.BaseMutationOptions<AddToAnalyticsMutation, AddToAnalyticsMutationVariables>;
export const FileUploadDocument = gql`
    mutation FileUpload($file: Upload!) {
  fileUpload(file: $file) {
    ...FileFragment
  }
}
    ${FileFragmentFragmentDoc}`;
export type FileUploadMutationFn = Apollo.MutationFunction<FileUploadMutation, FileUploadMutationVariables>;

/**
 * __useFileUploadMutation__
 *
 * To run a mutation, you first call `useFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUploadMutation, { data, loading, error }] = useFileUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<FileUploadMutation, FileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileUploadMutation, FileUploadMutationVariables>(FileUploadDocument, options);
      }
export type FileUploadMutationHookResult = ReturnType<typeof useFileUploadMutation>;
export type FileUploadMutationResult = Apollo.MutationResult<FileUploadMutation>;
export type FileUploadMutationOptions = Apollo.BaseMutationOptions<FileUploadMutation, FileUploadMutationVariables>;