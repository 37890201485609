import { useState, useEffect } from 'react';
import axios from 'axios';

const useLocationFromIpAddress = () => {
  const [country, setCountry] = useState<string | null>(null);
  const [region, setRegion] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        setCountry(response.data.country_name);
        setCity(response.data.city)
        setRegion(response.data.region)
      } catch (err) {
        setCountry(null);
        setCity(null);
        setRegion(null);
      } finally {
        setLoading(false)
      }
    };

    fetchLocation();
  }, []);

  return { country, loading, city, region };
};

export default useLocationFromIpAddress;
