import { useParams } from "react-router-dom"
import { useSkuQuery } from "../graphql/hooks/queries.generated"
import Switcher from "../landingPages/Switcher"
import SimpleLoadingPlaceholder from "../components/SimpleLoadingPlaceholder"

export type SkuByIdOrGtinProps = {
  gtin?: string
}

export const SkuByIdOrGtin = (props: SkuByIdOrGtinProps) => {
  const { id, gtin: gtinFromParams } = useParams()
  const { gtin: gtinFromProps } = props
  const gtin = gtinFromProps ?? gtinFromParams

  const skip = !id && !gtin
  const { data, loading, error } = useSkuQuery({
    variables: { id, gtin },
    skip,
  })
  const sku = data?.sku

  if (error) {
    return (
      <div>
        <h1>Error Loading Sku</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  if (!sku) {
    if (loading) return <SimpleLoadingPlaceholder />
    return <h1>404: Sku Not Found!</h1>
  }

  return <Switcher sku={sku} />
}

export default SkuByIdOrGtin
