import { useStyletron } from "baseui"
import { StyleObject } from "styletron-standard"

/**
 * A simple wrapper for the styletron we use in this project.
 * Takes in a keyed object with many styles and returns an identically keyed object with all the class names for using said styles.
 *
 * The downside to this is that it becomes hard to refactor and find unused styles.
 */
const useStyles = <T extends Record<string, StyleObject>>(styles: T) => {
  const [css] = useStyletron()
  const styleNames: Record<string, string> = {}
  Object.keys(styles).forEach((key) => (styleNames[key] = css(styles[key])))
  return styleNames as { [Key in keyof T]: string }
}

export default useStyles
