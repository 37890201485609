import ReactLoading from "react-loading"
import "./SimpleLoadingOverlay.css"

const SimpleLoadingOverlay = () => {
  return (
    <div className='loader'>
      <ReactLoading type='spin' color='#30f7feff' height={"10%"} width={"10%"} />
    </div>
  )
}

export default SimpleLoadingOverlay
