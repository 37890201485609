import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import en from "./en"
import fr from "./fr"
import es from "./es"
import pt from "./pt"
import de from "./de"
import id from "./id"
import it from "./it"
import ja from "./ja"
import ko from "./ko"
import vi from "./vi"
import zh from "./zh"

export const defaultNS = "Common"
export const resources = {
  en,
  fr,
  es,
  pt,
  de,
  it,
  zh,
  id,
  ja,
  ko,
  vi,
} as const

i18next.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  ns: Object.keys(en),
  defaultNS,
  resources,
})
