import { useParams } from "react-router-dom"
import { useLandingPageDataByRidQuery } from "../graphql/hooks/queries.generated"
import ErrorDisplayComponent from "../components/ErrorDisplay"
import useStyles from "../helpers/hooks/useStyles"
import Switcher from "../landingPages/Switcher"
import SimpleLoadingPlaceholder from "../components/SimpleLoadingPlaceholder"

const ProductByRid = () => {
  const styles = useStyles({
    error: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      maxWidth: "400px",
      margin: "0px auto",
    },
  })

  const { rid } = useParams()

  const { data, loading, error } = useLandingPageDataByRidQuery({
    variables: { rid: rid ?? "" },
    skip: !rid,
  })

  if (loading) {
    return <SimpleLoadingPlaceholder />
  }

  if (error) {
    return (
      <ErrorDisplayComponent className={styles.error} errorMessage={`Error loading batch data: ${error.message}`} />
    )
  }
  if (!data) {
    return <ErrorDisplayComponent className={styles.error} errorMessage='An unknown error occurred.' />
  }

  if (!data.product.sku) {
    return <h1>Invalid Product.</h1>
  }

  return <Switcher sku={data.product.sku} product={data.product} rid={rid} enableChainCompletion />
}

export default ProductByRid
