import React, { useEffect } from "react"
import LandingPageProps from "./LandingPageProps"
import { useSearchParams } from "react-router-dom"
import { useAddToAnalyticsMutation } from "../graphql/hooks/mutations.generated"
import useLocationFromIpAddress from "../helpers/hooks/useLocationFromIpAddress"
import { AnalyticsEventName } from "../types/enum"
import UnpublishedView from "./UnpublishedView"
import { DEVMODE } from "../Settings"

const MaterialLandingPage = React.lazy(() => import("./Material"))
const OriginalLandingPage = React.lazy(() => import("./Original"))
const SuperLandingPage = React.lazy(() => import("./SuperLandingPage"))
const ExampleLandingPage = React.lazy(() => import("./Example"))
const Template1 = React.lazy(() => import("./templates/Template1"))
const Template2 = React.lazy(() => import("./templates/Template2"))
const Template3 = React.lazy(() => import("./templates/Template3"))
const Template4 = React.lazy(() => import("./templates/Template4"))
const MiniApp = React.lazy(() => import("./templates/MiniApp"))

/**
 * This should be the primary source for known landing pages.
 * Changes to this enum should also be reflected in the `frontend-admin` and `backend` repos.
 */
export enum KnownLandingPages {
  Example = "Example",
  InteractiveMap = "Interactive Map",
  Default = "Default",
  Style1 = "Style 1",
  Style2 = "Style 2",
  Style3 = "Style 3",
  Style4 = "Style 4",
  MiniApp = "Mini App",
  Original = "Original",

  /**@deprecated: Use 'Original' instead. */
  Old = "Old",
  /**@deprecated: Use 'Default' instead. */
  New = "New",
}

type SwitcherProps = LandingPageProps & {
  show?: boolean
}

const Switcher = (props: SwitcherProps) => {
  const { sku, product, show } = props
  const [search] = useSearchParams({ landingPage: sku.landingPageVersion })
  const landingPage = search.get("landingPage")

  const [addToAnalytics, result] = useAddToAnalyticsMutation()
  const { country, loading, city, region } = useLocationFromIpAddress()

  useEffect(() => {
    if (!result.called && !loading) {
      if (product || sku) {
        addToAnalytics({
          variables: {
            input: {
              eventName: AnalyticsEventName.SCAN_QR1,
              eventData: product ?? sku,
              itemIdentifier: product ? product.id : sku.id,
              country: country,
              city: city,
              region: region,
            },
          },
        })
      }
    }
  }, [addToAnalytics, city, country, loading, product, region, result.called, sku])

  // Override selected landing page when this is a material sku
  if (product?.alias && product.alias !== "Product") return <MaterialLandingPage sku={sku} />

  // Hide landing page if sku is unpublished
  if (!DEVMODE && !show && !sku.isPublished) return <UnpublishedView {...props} />

  switch (landingPage as null | KnownLandingPages) {
    case "Example":
      return <ExampleLandingPage {...props} />

    case "Old":
    case "Original":
      return <OriginalLandingPage {...props} />

    case "Style 1":
      return <Template1 {...props} />

    case "Style 2":
      return <Template2 {...props} />

    case "Style 3":
      return <Template3 {...props} />

    case "Style 4":
      return <Template4 {...props} />

    case "Mini App":
      return <MiniApp {...props} />

    case "Interactive Map":
      return <SuperLandingPage {...props} showInteractiveMap />

    case "New":
    case "Default":
    default:
      return <SuperLandingPage {...props} showInteractiveMap={false} />
  }
}

export default Switcher
